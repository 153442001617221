<template>
  <div class="main-head-section row">
    <div class="col-58">
      <div class="main-head-section__region" v-if="region">
        {{ region }}
      </div>

      <h1 class="main-head-section__title">
        Откройте для себя образовательные возможности региона
      </h1>

      <div class="counters" v-if="counters.length !== 0">
        <div class="counters__item" v-for="(counter, key) in counters" :key="key">
          <div class="counters__count">
            {{ counter.value }}
          </div>

          <div class="counters__name">
            {{ counter.text }}
          </div>
        </div>
      </div>

      <div class="main-head-section__search">
        <SearchInput @search="$router.push(`/search?query=${$event}`)"/>
      </div>
    </div>

    <div class="col-41">
      <div class="main-head-section__image">
        <img :src="require('@/assets/svg/home-header.svg')" alt="home-header" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/InputComponents/SearchInput';

export default {
  name: 'HeadSection',
  components: {SearchInput},
  props: {
    region: {
      type: String,
    },
    counters: {
      type: [Array, Object],
    },
  },
};
</script>
